<template>
	<div>
		<Preloader ref="preloader"></Preloader>

		<HeaderOther></HeaderOther>

		<section class="shop__buy">
			<div class="info">
				<h3>{{ $t('cart.login') }}: <span id="login">{{login}}</span></h3>
			</div>

			<table class="shop__buy__table" cellspacing="0">
				<thead>
					<tr>
						<th>{{ $t('cart.name') }}</th>
						<th>{{ $t('cart.modpack') }}</th>
						<th>{{ $t('cart.count') }}</th>
						<th>{{ $t('cart.price') }}</th>
					</tr>
				</thead>
				<tbody>
					<tr v-for="item in items()" :key="item.id" v-if="item">
						<td>{{item.title}}</td>
						<td><template v-if="modpack(item.modpack)">{{modpack(item.modpack).title}}</template></td>
						<td>{{item.count * item.amount}}</td>
						<td>{{(item.count * (item.cost - (item.cost / 100 * item.sale))).toFixed(2)}} €</td>
					</tr>
				</tbody>
			</table>

			<div class="button" @click="buyWithCard">{{ $t('cart.card-payment') }} (Visa, Mastercard)</div>
			<div class="button gray" @click="buyWithOtherMethods">{{ $t('cart.another-payment') }}</div>
		</section>

		<Footer></Footer>
	</div>
</template>

<script>
	import { mapGetters } from 'vuex';
	import Footer from "../components/Footer";
	import Preloader from "../components/Preloader";
	import HeaderOther from "../components/HeaderOther";

	export default {
		name: "CartPage",
		components: {Footer, Preloader, HeaderOther},
		computed: {
			...mapGetters([
				'modpack/list',
			]),

			login() {
				return this.query.login || 'Unknown';
			},
		},
		data: () => ({
			query: {},
			shopItems: {}
		}),
		methods: {
			items() {
				return Object.values(this.shopItems);
			},

			modpack(modpackId) {
				return this['modpack/list'][modpackId];
			},

			async buyWithCard() {
				const url = await window.ShopManager.getPaymentUrl(this.query.login.trim(), this.query.items, true);
				if (url)
					location.href = url;
			},

			async buyWithOtherMethods() {
				const url = await window.ShopManager.getPaymentUrl(this.query.login.trim(), this.query.items, false);
				if (url)
					location.href = url;
			},

			async buy() {
				const url = await window.ShopManager.getPaymentUrl(this.query.login.trim(), this.query.items);
				if (url)
					location.href = url;
			}
		},
		async created() {
			let queryString = decodeURIComponent(location.href.split('?')[1]);
			if (queryString) {
				queryString.split('&')?.forEach(queryParam => {
					let [key, value] = queryParam.split('=');
					this.query[key] = value;
				});

				if (this.query.items) {
					this.query.items = JSON.parse(this.query.items);

					for (let itemId of this.query.items) {
						let item = this.shopItems[itemId];
						if (!item) {
							item = await window.ShopManager.findOneById(itemId);
							this.shopItems[item.id] = item;
							item.count = 0;
						}

						item.count++;
					}

					this.$forceUpdate();
				}
			}
		},
		mounted() {
			this.$refs.preloader.$el.style.display = 'none';
		}
	}
</script>

<style scoped>
	.shop__buy {
		display: flex;
		flex-direction: column;
		align-items: center;
		color: #fff;
		margin: 10rem 0;
	}

	.shop__buy .info {
		margin-bottom: 2rem;
	}

	.shop__buy__table {
		min-width: 50vw;
		margin: 0 auto 2rem;
	}

	.shop__buy__table td {
		padding: .6rem 1rem;
		text-align: center;
		border-bottom: solid 0.1rem #fff;
	}

	.shop__buy__table tr:last-child td {
		border-bottom: none;
		padding: .5rem;
	}

	.shop__buy .button {
		width: 22em;
		padding: 1rem;
		margin-bottom: 0;
	}

	.shop__buy .button.gray {
		background: #323232;
	}

	/*.certs {*/
	/*	display: flex;*/
	/*	margin-top: 2rem;*/
	/*}*/

	/*.certs img {*/
	/*	height: 2rem;*/
	/*	image-rendering: auto;*/
	/*	margin-right: .5rem;*/
	/*}*/
</style>
